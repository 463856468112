import { RequestReadYesboonySearch } from '@yescapa-dev/ysc-api-js/modern'
import type { APICamperSearchQuery, AppCamperSearch } from '@yescapa-dev/ysc-api-js/modern'
import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'
import { SEARCH_REQUEST_EVENT, getSearchAnalyticsParams } from '~/utils/analytics/search'

export const useSearchStore = defineStore('search', () => {
  const filters = ref(initialSearchFiltersFactory())

  const { localeProperties } = useLocaleProperties()

  const { $apiConsumer } = useNuxtApp()

  const results = ref<AppCamperSearch[]>([])
  const isCrossedResults = ref<boolean>(false)
  const total = ref(0)

  const markedItemId = ref<number | null>(null)
  const hoveredItemId = ref<number | null>(null)

  const loading = ref(false)

  const page = ref(1)
  const pageSize = ref(20)
  const currentFilter = ref<string | null>(null)

  const renderMap = ref(false)
  const showModalFilters = ref(false)

  const showQuickSearch = ref(false)

  const markedItem = computed(() => {
    if (markedItemId.value === null) {
      return null
    }

    return results.value.find(camper => camper.id === markedItemId.value)
  })

  const searchCampers = async (query: APICamperSearchQuery) => {
    loading.value = true
    const { getReferentialMaps } = useReferentialStore()
    const { event } = useGtag()
    event(SEARCH_REQUEST_EVENT, {
      ...getSearchAnalyticsParams(query, getReferentialMaps('vehicle_type_as_int')),
    })

    /**
     * if no location provided, set default ones to get localized results
     * if marketing id is provided, skip default localization
     */
    if (!query.longitude && !query.latitude && !query.radius && !query.marketing_partnership) {
      const { yscCode } = localeProperties
      switch (yscCode) {
        case LOCALE_CODES.CA:
          query.longitude = 1.564178
          query.latitude = 41.520917
          query.radius = 141575
          break
        case LOCALE_CODES.DE:
          query.longitude = 10.299683
          query.latitude = 51.679368
          query.radius = 457513
          break
        case LOCALE_CODES.DE_AT:
          query.longitude = 14.849396
          query.latitude = 47.589494
          query.radius = 127436
          break
        case LOCALE_CODES.DE_CH:
          query.longitude = 8.307037
          query.latitude = 46.991494
          query.radius = 128894
          break
        case LOCALE_CODES.EN_GB:
          query.longitude = -2.8509521484375
          query.latitude = 54.348552775876065
          query.radius = 494541
          break
        case LOCALE_CODES.EN_IE:
          query.longitude = -8.033752
          query.latitude = 53.271783
          query.radius = 224061
          break
        case LOCALE_CODES.ES:
          query.longitude = -3.630981
          query.latitude = 39.749434
          query.radius = 570438
          break
        case LOCALE_CODES.FR:
          query.longitude = 1.8292236328125002
          query.latitude = 47.04766864046083
          query.radius = 445695
          break
        case LOCALE_CODES.FR_BE:
          query.longitude = 4.936981
          query.latitude = 50.715591
          query.radius = 119597
          break
        case LOCALE_CODES.FR_CH:
          query.longitude = 7.002411
          query.latitude = 46.985874
          query.radius = 128908
          break
        case LOCALE_CODES.IT:
          query.longitude = 13.562622
          query.latitude = 40.254377
          query.radius = 566089
          break
        case LOCALE_CODES.IT_CH:
          query.longitude = 8.852233886718752
          query.latitude = 46.09228143052649
          query.radius = 301939
          break
        case LOCALE_CODES.NL:
          query.longitude = 4.76944
          query.latitude = 52.123371
          query.radius = 115951
          break
        case LOCALE_CODES.NL_BE:
          query.longitude = 4.755707
          query.latitude = 51.517289
          query.radius = 117529
          break
        case LOCALE_CODES.PT:
          query.longitude = -9.004669
          query.latitude = 39.193948
          query.radius = 146592
          break
        // every forgotten locale have en-us params
        default:
          query.longitude = 6.976318
          query.latitude = 46.271037
          query.radius = 992262
      }
    }
    markedItemId.value = null
    try {
      const request = new RequestReadYesboonySearch({ query })
      const data = await $apiConsumer.send(request)
      results.value = data.results
      isCrossedResults.value = data.crossed_results
      total.value = data.count
    }
    finally {
      loading.value = false
    }
  }

  return {
    filters,
    results,
    total,
    markedItemId,
    markedItem,
    hoveredItemId,
    loading,
    page,
    pageSize,
    currentFilter,
    renderMap,
    showModalFilters,
    searchCampers,
    showQuickSearch,
    isCrossedResults,
  }
})
